import React from 'react'
import { Helmet } from "react-helmet"

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion'

const FAQS = (props) => {

    const data = props.data;

    function getLtdData() {

        let mainEntity = {
            mainEntity: [
                data.faqs.map((faq, i) => {
                    return (
                        {
                            "@type": "Question",
                            "name": `"${faq.question}"`,
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": faq.answer
                            }
                        })
                })
            ]
        };

        let cit = JSON.stringify(mainEntity)
        let result = cit.substring(1, cit.length - 1);

        return result;
    }

    return (
        <div className="py-20 px-4" style={{ backgroundColor: "rgb(246, 250, 255)" }}>
            { typeof window != "undefined" && (
                <Helmet>
                    <script type="application/ld+json">
                        {`{
                        "@context": "https://schema.org",
                        "@type": "FaqPage",
                        ${getLtdData()}
                        }`}
                    </script>
                </Helmet>
            )}

            <div className="container m-auto">
                <h2 className="text-center" style={{ paddingBottom: "2rem" }}>{data.title}</h2>
                <div className="faq-accordion">
                    <Accordion>
                        {data.faqs.map((faq, i) => {
                            if(faq.visibleInSite)
                            return (
                            <AccordionItem key={`faq-${i}`}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <div className="pl-6">
                                            {faq.question}
                                        </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                </AccordionItemPanel>
                            </AccordionItem>
                        )})}
                    </Accordion>
                </div>
            </div>
        </div>

    );
}

export default FAQS